<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-if="!getLoading && requestData">
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <h1 class="text-h4 mb-5">{{ 'createBooking'|localize }}</h1>
        </v-col>
      </v-row>
      <v-stepper v-model="activeStep" alt-labels class="elevation-0 transparent">
        <v-stepper-header rounded class="elevation-0">
          <v-stepper-step
            :rules="[() => this.bookingFormValid[0]]"
            :step="1"
            :complete="stepProgress >= 1 && this.bookingFormValid[0]"
            :editable="stepProgress >= 1"
          >
            <span class="text-caption">{{ 'senderShipper'|localize }}</span>
          </v-stepper-step>
          <v-divider :class="activeStep > 1 ? 'divider active' : 'divider'"/>
          <v-stepper-step
            :rules="[() => this.bookingFormValid[1]]"
            :step="2"
            :complete="stepProgress >= 2 && this.bookingFormValid[1]"
            :editable="stepProgress >= 2"
          >
            <span class="text-caption">{{ 'receiverConsignee'|localize }}</span>
          </v-stepper-step>
          <v-divider :class="activeStep > 2 ? 'divider active' : 'divider'"/>
          <v-stepper-step
            :rules="[() => this.bookingFormValid[2]]"
            :step="3"
            :complete="stepProgress >= 3 && this.bookingFormValid[2]"
            :editable="stepProgress >= 3"
          >
            <span class="text-caption">{{ 'extraInformation'|localize }}</span>
          </v-stepper-step>
          <v-divider :class="activeStep > 3 ? 'divider active' : 'divider'"/>
          <v-stepper-step :step="4" :complete="stepProgress >= 4" :editable="stepProgress >= 4">
            <span class="text-caption text-uppercase">{{ 'bookingSummary'|localize }}</span>
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content :step="1" class="pb-10 pt-0 px-0">
            <v-form ref="formStep1" v-model="bookingFormValid[0]" lazy-validation class="mb-10">
              <v-row class="pb-8 pt-8">
                <v-col cols="12" class="text-left">
                  <h4 class="text-h4">{{ 'senderAddress'|localize }}</h4>
                </v-col>
              </v-row>
              <v-row v-if="requestData.send_from === PERSONALIZATION.COMPANY">
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'company'|localize }} *</h6>
                  <v-text-field
                    v-model="sender.company"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addYourCompanyName'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'vatNumber'|localize }}</h6>
                  <v-text-field
                    v-model="sender.vat_number"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addVatNumber'|localize"
                  />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'firstName'|localize }} *</h6>
                  <v-text-field
                    v-model="sender.firstName"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addYourFirstName'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'lastName'|localize }} *</h6>
                  <v-text-field
                    v-model="sender.lastName"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addYourLastName'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'streetNameNumber'|localize }} *</h6>
                  <v-text-field
                    v-model="sender.streetName"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addStreetNameNumber'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="2">
                  <h6 class="text-subtitle-1 mb-4">{{ 'zip'|localize }} *</h6>
                  <v-text-field
                    v-model="sender.zip"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addZip'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="2">
                  <h6 class="text-subtitle-1 mb-4">{{ 'city'|localize }} *</h6>
                  <v-text-field
                    v-model="sender.city"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addYourCity'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="2">
                  <h6 class="text-subtitle-1 mb-4">{{ 'country'|localize }} *</h6>
                  <v-autocomplete
                    v-model="sender.country_short"
                    :items="COUNTRY_LIST"
                    :item-text="item => `${item.name} (${item.code})`"
                    item-value="code"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'selectCountry'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'contactPerson'|localize }} *</h6>
                  <v-text-field
                    v-model="sender.contactPerson"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addContactPerson'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'phoneNumber'|localize }}</h6>
                  <VuePhoneNumberInput
                    v-model="sender.phone"
                    no-use-browser-locale
                    no-example
                    show-code-on-list
                    @update="phoneUpdate($event, 'sender')"
                    :translations="phoneNumberPlaceholders"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'email'|localize }} *</h6>
                  <v-text-field
                    v-model="sender.email"
                    dense
                    outlined
                    background-color="white"
                    placeholder="email@example.com"
                    :rules="[validationRules.required_long, validationRules.email]"
                  />
                </v-col>
              </v-row>
              <v-divider class="mt-8 mb-8"/>
              <v-row class="pb-8">
                <v-col cols="12" class="text-left">
                  <h4 class="text-h4">{{ 'collectionAddress'|localize }}</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <h6 class="text-subtitle-1 mb-4">{{ 'collectionAsSender'|localize }}</h6>
                  <v-radio-group row v-model="sameCollectionAddress">
                    <v-row>
                      <v-col cols="6">
                        <v-radio :value="true" color="orange" :ripple="false">
                          <template v-slot:label>
                            <span class="black--text">{{ 'yes'|localize }}</span>
                          </template>
                        </v-radio>
                      </v-col>
                      <v-col cols="6">
                        <v-radio :value="false" color="orange" :ripple="false">
                          <template v-slot:label>
                            <span class="black--text">{{ 'no'|localize }}</span>
                          </template>
                        </v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="!sameCollectionAddress">
                <v-col cols="4">
                  <h6 class="text-subtitle-1 mb-4">{{ 'isTerminalTopicFrom'|localize }}</h6>
                  <v-radio-group v-model="collection['is_terminal']">
                    <v-radio :value="false" color="orange" :ripple="false">
                      <template v-slot:label>
                        <span class="black--text">{{ 'isNotTerminalDirectionFrom'|localize }}</span>
                      </template>
                    </v-radio>
                    <v-radio :value="true" color="orange" :ripple="false">
                      <template v-slot:label>
                        <span class="black--text">{{ 'isTerminalDirectionFrom'|localize }}</span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <template v-if="isTerminalCollection">
                <v-row>
                  <v-col cols="4">
                    <h6 class="text-subtitle-1 mb-4">{{ 'country'|localize }} *</h6>
                    <v-autocomplete
                      v-model="collection.country_short"
                      :items="COUNTRY_LIST"
                      :item-text="item => `${item.name} (${item.code})`"
                      item-value="code"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'selectCountry'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                  <v-col cols="4">
                    <h6 class="text-subtitle-1 mb-4">{{ 'terminal'|localize }} *</h6>
                    <v-text-field
                      v-model="collection['terminal_number']"
                      dense
                      outlined
                      background-color="white"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-if="isNotTerminalCollection">
                <v-row v-if="requestData.send_from === PERSONALIZATION.COMPANY">
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'company'|localize }} *</h6>
                    <v-text-field
                      v-model="collection.company"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addYourCompanyName'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'firstName'|localize }} *</h6>
                    <v-text-field
                      v-model="collection.firstName"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addYourFirstName'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'lastName'|localize }} *</h6>
                    <v-text-field
                      v-model="collection.lastName"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addYourLastName'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'streetNameNumber'|localize }} *</h6>
                    <v-text-field
                      v-model="collection.streetName"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addStreetNameNumber'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                  <v-col cols="2">
                    <h6 class="text-subtitle-1 mb-4">{{ 'zip'|localize }} *</h6>
                    <v-text-field
                      v-model="collection.zip"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addZip'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                  <v-col cols="2">
                    <h6 class="text-subtitle-1 mb-4">{{ 'city'|localize }} *</h6>
                    <v-text-field
                      v-model="collection.city"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addYourCity'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                  <v-col cols="2">
                    <h6 class="text-subtitle-1 mb-4">{{ 'country'|localize }} *</h6>
                    <v-autocomplete
                      v-model="collection.country_short"
                      :items="COUNTRY_LIST"
                      :item-text="item => `${item.name} (${item.code})`"
                      item-value="code"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'selectCountry'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'contactPerson'|localize }} *</h6>
                    <v-text-field
                      v-model="collection.contactPerson"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addContactPerson'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'phoneNumber'|localize }}</h6>
                    <VuePhoneNumberInput
                      v-model="collection.phone"
                      no-use-browser-locale
                      no-example
                      show-code-on-list
                      @update="phoneUpdate($event, 'collection')"
                      :translations="phoneNumberPlaceholders"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'email'|localize }} *</h6>
                    <v-text-field
                      v-model="collection.email"
                      dense
                      outlined
                      background-color="white"
                      placeholder="email@example.com"
                      :rules="[validationRules.required_long, validationRules.email]"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-form>
            <v-row class="d-flex justify-end pb-1">
              <v-col cols="auto">
                <v-btn color="primary" rounded depressed @click="bookingFormValidation(1, 'formStep1')">
                  {{ 'nextStep'|localize }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content :step="2" class="pb-10 pt-0 px-0">
            <v-form ref="formStep2" v-model="bookingFormValid[1]" lazy-validation class="mb-10">
              <v-row class="pb-8 pt-8">
                <v-col cols="12" class="text-left">
                  <h4 class="text-h4">{{ 'receiverAddress'|localize }}</h4>
                </v-col>
              </v-row>
              <v-row v-if="requestData.send_to === PERSONALIZATION.COMPANY">
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'company'|localize }} *</h6>
                  <v-text-field
                    v-model="receiver.company"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addYourCompanyName'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4"> {{ 'vatNumber'|localize }}</h6>
                  <v-text-field
                    v-model="receiver.vat_number"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addVatNumber'|localize"
                  />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'firstName'|localize }} *</h6>
                  <v-text-field
                    v-model="receiver.firstName"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addYourFirstName'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'lastName'|localize }} *</h6>
                  <v-text-field
                    v-model="receiver.lastName"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addYourLastName'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'streetNameNumber'|localize }} *</h6>
                  <v-text-field
                    v-model="receiver.streetName"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addStreetNameNumber'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="2">
                  <h6 class="text-subtitle-1 mb-4">{{ 'zip'|localize }} *</h6>
                  <v-text-field
                    v-model="receiver.zip"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addZip'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="2">
                  <h6 class="text-subtitle-1 mb-4">{{ 'city'|localize }} *</h6>
                  <v-text-field
                    v-model="receiver.city"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addYourCity'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="2">
                  <h6 class="text-subtitle-1 mb-4">{{ 'country'|localize }} *</h6>
                  <v-autocomplete
                    v-model="receiver.country_short"
                    :items="COUNTRY_LIST"
                    :item-text="item => `${item.name} (${item.code})`"
                    item-value="code"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'selectCountry'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'contactPerson'|localize }} *</h6>
                  <v-text-field
                    v-model="receiver.contactPerson"
                    dense
                    outlined
                    background-color="white"
                    :placeholder="'addContactPerson'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'phoneNumber'|localize }}</h6>
                  <VuePhoneNumberInput
                    v-model="receiver.phone"
                    no-use-browser-locale
                    no-example
                    show-code-on-list
                    @update="phoneUpdate($event, 'receiver')"
                    :translations="phoneNumberPlaceholders"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'email'|localize }} *</h6>
                  <v-text-field
                    v-model="receiver.email"
                    dense
                    outlined
                    background-color="white"
                    placeholder="email@example.com"
                    :rules="[validationRules.required_long, validationRules.email]"
                  />
                </v-col>
              </v-row>
              <v-divider class="mt-8 mb-8"/>
              <v-row class="pb-8">
                <v-col cols="12" class="text-left">
                  <h4 class="text-h4">{{ 'deliveryAddress'|localize }}</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <h6 class="text-subtitle-1 mb-4">{{ 'deliveryAsReceiver'|localize }}</h6>
                  <v-radio-group row v-model="sameDeliveryAddress">
                    <v-row>
                      <v-col cols="6">
                        <v-radio :value="true" color="orange" :ripple="false">
                          <template v-slot:label>
                            <span class="black--text">{{ 'yes'|localize }}</span>
                          </template>
                        </v-radio>
                      </v-col>
                      <v-col cols="6">
                        <v-radio :value="false" color="orange" :ripple="false">
                          <template v-slot:label>
                            <span class="black--text">{{ 'no'|localize }}</span>
                          </template>
                        </v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="!sameDeliveryAddress">
                <v-col cols="4">
                  <h6 class="text-subtitle-1 mb-4">{{ 'isTerminalTopicTo'|localize }}</h6>
                  <v-radio-group v-model="delivery['is_terminal']">
                    <v-radio :value="false" color="orange" :ripple="false">
                      <template v-slot:label>
                        <span class="black--text">{{ 'isNotTerminalDirectionTo'|localize }}</span>
                      </template>
                    </v-radio>
                    <v-radio :value="true" color="orange" :ripple="false">
                      <template v-slot:label>
                        <span class="black--text">{{ 'isTerminalDirectionTo'|localize }}</span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <template v-if="isTerminalDelivery">
                <v-row>
                  <v-col cols="4">
                    <h6 class="text-subtitle-1 mb-4">{{ 'country'|localize }} *</h6>
                    <v-autocomplete
                      v-model="delivery.country_short"
                      :items="COUNTRY_LIST"
                      :item-text="item => `${item.name} (${item.code})`"
                      item-value="code"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'selectCountry'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                  <v-col cols="4">
                    <h6 class="text-subtitle-1 mb-4">{{ 'terminal'|localize }} *</h6>
                    <v-text-field
                      v-model="delivery['terminal_number']"
                      dense
                      outlined
                      background-color="white"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-if="isNotTerminalDelivery">
                <v-row v-if="requestData.send_to === PERSONALIZATION.COMPANY">
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'company'|localize }} *</h6>
                    <v-text-field
                      v-model="delivery.company"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addYourCompanyName'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'firstName'|localize }} *</h6>
                    <v-text-field
                      v-model="delivery.firstName"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addYourFirstName'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'lastName'|localize }} *</h6>
                    <v-text-field
                      v-model="delivery.lastName"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addYourLastName'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'streetNameNumber'|localize }} *</h6>
                    <v-text-field
                      v-model="delivery.streetName"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addStreetNameNumber'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                  <v-col cols="2">
                    <h6 class="text-subtitle-1 mb-4">{{ 'zip'|localize }} *</h6>
                    <v-text-field
                      v-model="delivery.zip"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addZip'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                  <v-col cols="2">
                    <h6 class="text-subtitle-1 mb-4">{{ 'city'|localize }} *</h6>
                    <v-text-field
                      v-model="delivery.city"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addYourCity'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                  <v-col cols="2">
                    <h6 class="text-subtitle-1 mb-4">{{ 'country'|localize }} *</h6>
                    <v-autocomplete
                      v-model="delivery.country_short"
                      :items="COUNTRY_LIST"
                      :item-text="item => `${item.name} (${item.code})`"
                      item-value="code"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'selectCountry'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'contactPerson'|localize }} *</h6>
                    <v-text-field
                      v-model="delivery.contactPerson"
                      dense
                      outlined
                      background-color="white"
                      :placeholder="'addContactPerson'|localize"
                      :rules="[validationRules.required_long]"
                    />
                  </v-col>
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'phoneNumber'|localize }}</h6>
                    <VuePhoneNumberInput
                      v-model="delivery.phone"
                      no-use-browser-locale
                      no-example
                      show-code-on-list
                      @update="phoneUpdate($event, 'delivery')"
                      :translations="phoneNumberPlaceholders"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <h6 class="text-subtitle-1 mb-4">{{ 'email'|localize }} *</h6>
                    <v-text-field
                      v-model="delivery.email"
                      dense
                      outlined
                      background-color="white"
                      placeholder="email@example.com"
                      :rules="[validationRules.required_long, validationRules.email]"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-form>
            <v-row class="d-flex justify-space-between pb-1">
              <v-col cols="auto">
                <v-btn color="primary" outlined rounded @click="activeStep = 1;" href="#header">
                  {{ 'previousStep'|localize }}
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" rounded depressed @click="bookingFormValidation(2, 'formStep2')">
                  {{ 'nextStep'|localize }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content :step="3" class="pb-10 pt-0 px-0">
            <v-form ref="formStep3" v-model="bookingFormValid[2]" lazy-validation class="mb-10">
              <v-row class="pb-8 pt-8">
                <v-col cols="12" class="text-left">
                  <h4 class="text-h4">{{ 'extraInformation'|localize }}</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'loading'|localize }} *</h6>
                  <v-menu
                    ref="menuStart"
                    v-model="menu_start"
                    :close-on-content-click="false"
                    :return-value.sync="loading_date"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        background-color="white"
                        :placeholder="'selectDate' | localize"
                        prepend-inner-icon="mdi-calendar"
                        v-model="loading_date_formatted"
                        readonly
                        dense
                        outlined
                        v-on="on"
                        :rules="[validationRules.required]"
                        clearable
                        @click:clear="loading_date = []"
                      />
                    </template>
                    <v-date-picker v-model="loading_date" no-title scrollable multiple>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" depressed rounded @click="menu_start = false">{{ 'cancel'|localize }}</v-btn>
                      <v-btn color="primary" depressed rounded @click="$refs.menuStart.save(loading_date)">OK</v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'otherInformation'|localize }}</h6>
                  <v-textarea
                    v-model="otherInformation"
                    outlined
                    rows="3"
                    background-color="white"
                    hide-details
                    :placeholder="'otherInformation'|localize"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-4">{{ 'uploadDocuments'|localize }}</h6>
                  <div class="d-flex mb-2">
                    <div class="d-flex flex-column">
                      <div class="body-2">
                        ({{ 'uploadDocumentsList'|localize }})<br/>PDF, JPG, JPEG, DOC, DOCX<br/>{{ 'maxFileSize'|localize }} - 50Mb
                      </div>
                    </div>
                    <v-btn
                      color="primary"
                      class="white--text text-h4 text-center ml-4"
                      fab
                      small
                      depressed
                      @click="handleFileImport"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/jpg, image/jpeg, application/pdf, application/doc, application/docx"
                    multiple="multiple"
                    @change="onFileChanged"
                  >
                  <template v-if="uploadedDocumentsFile.length > 0">
                    <div class="text-caption mb-2 text-uppercase">{{ 'attachedDocuments'|localize }}:</div>
                    <v-chip
                      class="mb-2 mr-2 text-overflow"
                      v-for="item in uploadedDocumentsFile"
                      :key="item.id"
                      close-icon="mdi-delete"
                      @click:close="removeFile(item.id)"
                      close
                      outlined
                      label
                    >
                    <span class="text-truncate black--text">
                      {{ item.name }}
                    </span>
                    </v-chip>
                  </template>
                </v-col>
              </v-row>
            </v-form>
            <v-row class="d-flex justify-space-between pb-1">
              <v-col cols="auto">
                <v-btn color="primary" outlined rounded @click="activeStep = 2;">
                  {{ 'previousStep'|localize }}
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" rounded depressed @click="bookingFormValidation(3, 'formStep3')">
                  {{ 'nextStep'|localize }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content :step="4" class="pb-10 pt-0 px-0">
            <v-form class="mb-10">
              <v-row class="pb-8 pt-8">
                <v-col cols="12" class="text-left">
                  <h4 class="text-h4">{{ 'bookingSummary'|localize }}</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <h6 class="text-subtitle-1 mb-1">{{ 'transportMode'|localize }}</h6>
                  <h6 class="text-body-1 d-flex align-center mb-5">
                    <v-icon color="silver-chalice" class="mr-1">{{ TRANSPORT_ICONS[transportMode] }}</v-icon>
                    {{ transportMode|localize }}
                  </h6>
                  <template v-if="requestData && requestData.recurring_start">
                    <h6 class="text-subtitle-1 mb-1">{{ 'recurringNeeds'|localize }}</h6>
                    <div class='d-flex mb-2'>
                      <div class="text-body-1 mr-3">{{ 'startDate'|localize }}: {{ requestData.recurring_start || '--' }}</div>
                      <div class="text-body-1">{{ 'endDate'|localize }}: {{ requestData.recurring_end || '--' }}</div>
                    </div>
                    <div class="text-body-1">{{ 'numberOfShipments'|localize }}: {{ requestData.recurring_shipments || '--' }}</div>
                  </template>
                </v-col>
                <v-col cols="4">
                  <h5 class="text-h5 mb-1">{{ 'senderAddress'|localize }}</h5>
                  <template v-if="requestData.send_from === PERSONALIZATION.COMPANY">
                    <h6 class="text-subtitle-1 mb-1">{{ 'company'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{sender.company}}</h6>
                    <template v-if="sender.vat_number">
                      <h6 class="text-subtitle-1 mb-1">{{ 'vatNumber'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{sender.vat_number}}</h6>
                    </template>
                  </template>
                  <template v-else>
                    <h6 class="text-subtitle-1 mb-1">{{ 'firstName'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{sender.firstName}}</h6>
                    <h6 class="text-subtitle-1 mb-1">{{ 'lastName'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{sender.lastName}}</h6>
                  </template>
                  <template v-if="sender.contactPerson">
                    <h6 class="text-subtitle-1 mb-1">{{ 'contactPerson'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{sender.contactPerson}}</h6>
                  </template>
                  <template v-if="sender.phone">
                    <h6 class="text-subtitle-1 mb-1">{{ 'phoneNumber'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{sender.phoneFull.formattedNumber}}</h6>
                  </template>
                  <template v-if="sender.email">
                    <h6 class="text-subtitle-1 mb-1">{{ 'email'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{sender.email}}</h6>
                  </template>
                  <h6 class="text-subtitle-1 mb-1">{{ 'senderAddress'|localize }}</h6>
                  <h6 class="text-body-1">{{ computedSenderAddress }}</h6>
                  <template v-if="isTerminalCollection">
                    <h5 class="text-h5 mt-8 mb-1">{{ 'collectionAddress'|localize }}</h5>
                    <h5 class="text-subtitle-1 mb-1">{{ 'isTerminalDirectionFrom'|localize }}</h5>
                    <h6 class="text-body-1">{{ computedCollectionTerminalAddress }}</h6>
                  </template>
                  <template v-if="isNotTerminalCollection">
                    <h5 class="text-h5 mt-8 mb-1">{{ 'collectionAddress'|localize }}</h5>
                    <template v-if="requestData.send_from === PERSONALIZATION.COMPANY">
                      <h6 class="text-subtitle-1 mb-1">{{ 'company'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{collection.company}}</h6>
                    </template>
                    <template v-else>
                      <h6 class="text-subtitle-1 mb-1">{{ 'firstName'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{collection.firstName}}</h6>
                      <h6 class="text-subtitle-1 mb-1">{{ 'lastName'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{collection.lastName}}</h6>
                    </template>
                    <template v-if="collection.contactPerson">
                      <h6 class="text-subtitle-1 mb-1">{{ 'contactPerson'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{collection.contactPerson}}</h6>
                    </template>
                    <template v-if="collection.phone">
                      <h6 class="text-subtitle-1 mb-1">{{ 'phoneNumber'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{collection.phoneFull.formattedNumber}}</h6>
                    </template>
                    <template v-if="collection.email">
                      <h6 class="text-subtitle-1 mb-1">{{ 'email'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{collection.email}}</h6>
                    </template>
                    <h4 class="text-subtitle-1 mb-1">{{ 'collectionAddress'|localize }}</h4>
                    <h6 class="text-body-1">{{ computedCollectionAddress }}</h6>
                  </template>
                </v-col>
                <v-col cols="4">
                  <h5 class="text-h5 mb-1">{{ 'receiverAddress'|localize }}</h5>
                  <template v-if="requestData.send_to === PERSONALIZATION.COMPANY">
                    <h6 class="text-subtitle-1 mb-1">{{ 'company'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{receiver.company}}</h6>
                    <template v-if="receiver.vat_number">
                      <h6 class="text-subtitle-1 mb-1">{{ 'vatNumber'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{receiver.vat_number}}</h6>
                    </template>
                  </template>
                  <template v-else>
                    <h6 class="text-subtitle-1 mb-1">{{ 'firstName'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{receiver.firstName}}</h6>
                    <h6 class="text-subtitle-1 mb-1">{{ 'lastName'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{receiver.lastName}}</h6>
                  </template>
                  <template v-if="receiver.contactPerson">
                    <h6 class="text-subtitle-1 mb-1">{{ 'contactPerson'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{receiver.contactPerson}}</h6>
                  </template>
                  <template v-if="receiver.phone">
                    <h6 class="text-subtitle-1 mb-1">{{ 'phoneNumber'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{receiver.phoneFull.formattedNumber}}</h6>
                  </template>
                  <template v-if="receiver.email">
                    <h6 class="text-subtitle-1 mb-1">{{ 'email'|localize }}</h6>
                    <h6 class="text-body-1 mb-4">{{receiver.email}}</h6>
                  </template>
                  <h6 class="text-subtitle-1 mb-1">{{ 'receiverAddress'|localize }}</h6>
                  <h6 class="text-body-1">{{ computedReceiverAddress }}</h6>
                  <template v-if="isTerminalDelivery">
                    <h5 class="text-h5 mt-8 mb-1">{{ 'deliveryAddress'|localize }}</h5>
                    <h5 class="text-subtitle-1 mb-1">{{ 'isTerminalDirectionTo'|localize }}</h5>
                    <h6 class="text-body-1">{{ computedDeliveryTerminalAddress }}</h6>
                  </template>
                  <template v-if="isNotTerminalDelivery">
                    <h5 class="text-h5 mt-8 mb-1">{{ 'deliveryAddress'|localize }}</h5>
                    <template v-if="requestData.send_to === PERSONALIZATION.COMPANY">
                      <h6 class="text-subtitle-1 mb-1">{{ 'company'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{delivery.company}}</h6>
                    </template>
                    <template v-else>
                      <h6 class="text-subtitle-1 mb-1">{{ 'firstName'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{delivery.firstName}}</h6>
                      <h6 class="text-subtitle-1 mb-1">{{ 'lastName'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{delivery.lastName}}</h6>
                    </template>
                    <template v-if="delivery.contactPerson">
                      <h6 class="text-subtitle-1 mb-1">{{ 'contactPerson'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{delivery.contactPerson}}</h6>
                    </template>
                    <template v-if="delivery.phone">
                      <h6 class="text-subtitle-1 mb-1">{{ 'phoneNumber'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{delivery.phoneFull.formattedNumber}}</h6>
                    </template>
                    <template v-if="delivery.email">
                      <h6 class="text-subtitle-1 mb-1">{{ 'email'|localize }}</h6>
                      <h6 class="text-body-1 mb-4">{{delivery.email}}</h6>
                    </template>
                    <h6 class="text-subtitle-1 mb-1">{{ 'deliveryAddress'|localize }}</h6>
                    <h6 class="text-body-1">{{ computedDeliveryAddress }}</h6>
                  </template>
                </v-col>
              </v-row>
              <v-divider class="my-10"/>
              <v-row class="pb-4">
                <v-col cols="auto">
                  <h5 class="text-h5 mb-2">{{ 'goods'|localize }}</h5>
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col cols="12">
                  <GoodComponent
                    v-for="(item, index) in requestData.goods"
                    :currentItem="item"
                    :goodTypes="goodTypesSorted.all"
                    :requestType="requestData.request_type"
                    :transportMode="[transportMode]"
                    :item="item"
                    :number="index"
                    :key="item.id"
                    :disabled="true"
                  />
                </v-col>
              </v-row>
              <GoodsSummary
                :totalPackages="requestData.goods.length"
                :totalWeight="requestData.weight"
                :totalVolume="requestData.volume"
              />
              <v-divider class="mb-10"/>
              <v-row class="mb-10">
                <v-col cols="auto">
                  <h5 class="text-h5">{{ 'expectations'|localize }}</h5>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'loading'|localize }}</h6>
                  <h6 class="text-body-1 mb-4" v-html="$options.filters.columnizeDates(loading_date)" />
                  <h6 class="text-subtitle-1 mb-1">{{ 'slotTimeLoading'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">
                    <template v-if="requestData.slot_time_loading">{{ requestData.slot_time_loading.from }} - {{ requestData.slot_time_loading.to }}</template>
                    <template v-else>--</template>
                  </h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'unloading'|localize }}</h6>
                  <h6 class="text-body-1 mb-4" v-html="$options.filters.columnizeDates(requestData.unloading_date)" />
                  <h6 class="text-subtitle-1 mb-1">{{ 'slotTimeDelivery'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">
                    <template v-if="requestData.slot_time_delivery">{{ requestData.slot_time_delivery.from }} - {{ requestData.slot_time_delivery.to }}</template>
                    <template v-else>--</template>
                  </h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'deliveryTerms'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">{{ requestData.delivery_terms }}</h6>
                </v-col>
              </v-row>
              <v-divider class="mb-10"/>
              <v-row class="mb-10">
                <v-col cols="auto">
                  <h5 class="text-h5">{{ 'requirements'|localize }}</h5>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-1 d-flex align-center">
                    <v-icon class="mr-1" :color="SPECIAL_REQUIREMENTS_COLORS[SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS]">{{ SPECIAL_REQUIREMENTS_ICONS[SPECIAL_REQUIREMENTS_TYPES.DANGEROUS_GOODS] }}</v-icon>
                    {{ 'dangerous_goods'|localize }}
                  </h6>
                  <h6 class="text-body-1 mb-10">
                    <template v-if="requestData.dangerous_goods_number">
                      UN-{{requestData.dangerous_goods_number}} /
                      <template v-if="requestData['attachment_name']">
                        <a :href="requestData['attachment']" target="_blank">{{requestData['attachment_name']}}</a>
                      </template>
                      <template v-else>#</template>
                    </template>
                    <template v-else>--</template>
                  </h6>
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-1 d-flex align-center">
                    <v-icon class="mr-1" :color="SPECIAL_REQUIREMENTS_COLORS[SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS]">{{ SPECIAL_REQUIREMENTS_ICONS[SPECIAL_REQUIREMENTS_TYPES.TEMPERED_GOODS] }}</v-icon>
                    {{ 'tempered_goods'|localize }}
                  </h6>
                  <h6 class="text-body-1 mb-10">{{ requestData.tempered_goods ? `${requestData.tempered_goods[0]}ºC - ${requestData.tempered_goods[1]}ºC` : '--' }}</h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-1">{{ 'goodsType'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">{{ computedProductType }}</h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'tailLiftLoading'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">
                    <template v-if="requestData.tail_lift_loading">{{ 'yes'|localize }}</template>
                    <template v-else>{{ 'no'|localize }}</template>
                  </h6>
                </v-col>
                <v-col cols="3">
                  <h6 class="text-subtitle-1 mb-1">{{ 'tailLiftDelivery'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">
                    <template v-if="requestData.tail_lift_delivery">{{ 'yes'|localize }}</template>
                    <template v-else>{{ 'no'|localize }}</template>
                  </h6>
                </v-col>
              </v-row>
              <v-divider class="mb-10"/>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-1">{{'containerLoadingUnloadingSweden'|localize}}</h6>
                  <h6 class="text-body-1 mb-10">
                    <template v-if="requestData.container_loading_unloading">{{ requestData.container_loading_unloading|localize }}</template>
                    <template v-else>--</template>
                  </h6>
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-1">{{ 'customClearance'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">
                    <template v-if="requestData.custom_clearance">{{ 'needHelp'|localize }}</template>
                    <template v-else-if="requestData.custom_clearance === false">{{ 'doItOurselves'|localize }}</template>
                    <template v-else>--</template>
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-1">{{ 'otherInformation'|localize }}</h6>
                  <h6 class="text-body-1 mb-10">{{ otherInformation ? otherInformation : '--' }}</h6>
                </v-col>
              </v-row>
            </v-form>
            <v-row class="d-flex justify-space-between pb-1">
              <v-col cols="auto">
                <v-btn color="primary" outlined rounded @click="activeStep = 3; stepProgress = 4">
                  {{ 'previousStep'|localize }}
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  rounded
                  depressed
                  :loading="sendLoading"
                  :disabled="sendLoading"
                  @click="$refs.sendDialog.open()"
                >
                  {{ 'sendBooking'|localize }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
    <SimpleDialog
      ref="sendDialog"
      :title="'sendBooking'|localize"
      :text="'sendRequestText'|localize"
      :accept-text="'sendBooking'|localize"
      :decline-text="'back'|localize"
      @accept="submitForm"
    />
  </v-container>
</template>

<script>
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import goTo from 'vuetify/lib/services/goto';
  import validationRules from '../helpers/validationRules';
  import api from '@/api/api';
  import {TRANSPORT_ICONS} from '@/enums/transportMode';
  import {COUNTRY_LIST} from '@/enums/countryList';
  import {PERSONALIZATION} from '@/enums/personalization';
  import methods from '@/helpers/methods';
  import localize from '@/filters/localize';
  import SimpleDialog from '@/components/common/SimpleDialog';
  import GoodComponent from '@/components/GoodComponent';
  import GoodsSummary from '@/components/GoodsSummary';
  import {
    SPECIAL_REQUIREMENTS_COLORS,
    SPECIAL_REQUIREMENTS_ICONS,
    SPECIAL_REQUIREMENTS_TYPES,
  } from '@/enums/specialRequirements';

  const initAddress = {
    company: '',
    vat_number: '',
    firstName: '',
    lastName: '',
    streetName: '',
    zip: '',
    city: '',
    country: '',
    country_short: '',
    contactPerson: '',
    email: '',
    phone: '',
    phoneFull: '',
    is_terminal: false,
    terminal_number: ''
  };

  export default {
    name: 'Booking',
    components: {GoodComponent, SimpleDialog, VuePhoneNumberInput, GoodsSummary},

    data() {
      return {
        TRANSPORT_ICONS,
        COUNTRY_LIST,
        PERSONALIZATION,
        validationRules: validationRules,
        requestId: this.$router.currentRoute.query.request_id,
        offerId: this.$router.currentRoute.query.offer_id,
        transportMode: this.$router.currentRoute.query.mode,
        getLoading: false,
        sendLoading: false,
        requestData: null,
        activeStep: 1,
        stepProgress: 1,
        bookingFormValid: [true, true, true, true],
        sameCollectionAddress: true,
        sameDeliveryAddress: true,
        sender: {...initAddress},
        collection: {...initAddress},
        receiver: {...initAddress},
        delivery: {...initAddress},
        menu_start: false,
        loading_date: [],
        uploadedDocumentsFile: [],
        otherInformation: "",
      };
    },

    async mounted() {
      try {
        this.getLoading = true;
        let remoteData = await api.getSingleCustomerRequest(this.requestId);
        this.requestData = remoteData.data['request_form'];
        this.requestData.loading_date && (this.loading_date = [...this.requestData.loading_date]);
        this.setRequestAddress(this.requestData.direction_from, 'sender');
        this.setRequestAddress(this.requestData.direction_to, 'receiver');
        this.getLoading = false;
      } catch (e) {
        this.getLoading = false;
      }
    },

    watch: {
      'sender.country_short'(newValue) {
        newValue ? (this.sender.country = COUNTRY_LIST.filter(countryData => countryData.code === newValue)[0].name) : this.sender.country = '';
      },
      'collection.country_short'(newValue) {
        newValue ? (this.collection.country = COUNTRY_LIST.filter(countryData => countryData.code === newValue)[0].name) : this.collection.country = '';
      },
      'receiver.country_short'(newValue) {
        newValue ? (this.receiver.country = COUNTRY_LIST.filter(countryData => countryData.code === newValue)[0].name) : this.receiver.country = '';
      },
      'delivery.country_short'(newValue) {
        newValue ? (this.delivery.country = COUNTRY_LIST.filter(countryData => countryData.code === newValue)[0].name) : this.delivery.country = '';
      },
    },

    computed: {
      SPECIAL_REQUIREMENTS_ICONS() {
        return SPECIAL_REQUIREMENTS_ICONS
      },
      SPECIAL_REQUIREMENTS_TYPES() {
        return SPECIAL_REQUIREMENTS_TYPES
      },
      SPECIAL_REQUIREMENTS_COLORS() {
        return SPECIAL_REQUIREMENTS_COLORS
      },
      productTypes() {
        return this.$store.getters.productTypes;
      },
      goodTypesSorted() {
        return this.$store.getters.goodTypesSorted;
      },
      loading_date_formatted: {
        get: function () {
          return this.loading_date.join(', ');
        },
        set: function (newValue) {
          return newValue
        }
      },
      computedProductType() {
        if (this.requestData.goods_type) {
          let existCheck = this.productTypes.findIndex(type => type.key === this.requestData.goods_type);
          if (existCheck >= 0) {
            return localize(this.requestData.goods_type);
          } else {
            return this.requestData.goods_type;
          }
        }
      },
      computedSenderAddress() {
        return this.getFullAddress(this.sender);
      },
      computedReceiverAddress() {
        return this.getFullAddress(this.receiver);
      },
      computedCollectionAddress() {
        if (this.sameCollectionAddress) {
          return this.computedSenderAddress;
        } else {
          return this.getFullAddress(this.collection);
        }
      },
      computedCollectionTerminalAddress() {
        return `${this.collection['terminal_number'] ? this.collection['terminal_number'] + ', ' : ''}${this.collection.country ? this.collection.country : ''}`;
      },
      computedDeliveryTerminalAddress() {
        return `${this.delivery['terminal_number'] ? this.delivery['terminal_number'] + ', ' : ''}${this.delivery.country ? this.delivery.country : ''}`;
      },
      computedDeliveryAddress() {
        if (this.sameDeliveryAddress) {
          return this.computedReceiverAddress;
        } else {
          return this.getFullAddress(this.delivery);
        }
      },
      breadcrumbsItems() {
        return [
          {
            text: localize('myQuotes'),
            disabled: false,
            to: {name: 'customer-requests'}
          },
          {
            text: localize('allQuotations'),
            to: {name: 'request-details', query: {request_id: this.requestId}},
            disabled: false,
          },
          {
            text: localize('booking'),
            disabled: true,
          },
        ]
      },
      phoneNumberPlaceholders() {
        return {
          countrySelectorLabel: localize('countryCode'),
          phoneNumberLabel: localize('phoneNumber'),
        }
      },
      isTerminalCollection() {
        return !this.sameCollectionAddress && this.collection['is_terminal'];
      },
      isNotTerminalCollection() {
        return !this.sameCollectionAddress && !this.collection['is_terminal'];
      },
      isTerminalDelivery() {
        return !this.sameDeliveryAddress && this.delivery['is_terminal']
      },
      isNotTerminalDelivery() {
        return !this.sameDeliveryAddress && !this.delivery['is_terminal']
      }
    },

    methods: {
      setRequestAddress(addressData, name) {
        this[name].country_short = addressData.country_short;
        addressData.street_address && (this[name].streetName = addressData.street_address);
        addressData.zip_code && (this[name].zip = addressData.zip_code);
        addressData.city && (this[name].city = addressData.city);
      },
      phoneUpdate(event, name) {
        this[name].phoneFull = event;
      },
      bookingFormValidation(step, ref) {
        if (!this.$refs[ref].validate()) {
          setTimeout(() => {
            const el = document.querySelector(".v-messages.theme--light.error--text").closest('.col');
            goTo(el);
          }, 300);
          return;
        }
        this.activeStep = step + 1;
        this.$parent.$refs.header.$el.scrollIntoView();
        if (this.stepProgress <= this.activeStep) {
          this.stepProgress = step + 1;
        }
      },
      getFullAddress(addressData) {
        return `${addressData.streetName ? addressData.streetName + ', ' : ''}${addressData.zip ? addressData.zip + ', ' : ''}${addressData.city ? addressData.city + ', ' : ''}${addressData.country ? addressData.country : ''}`;
      },
      queryAddress(personalization, role) {
        let localAddress = {};
        localAddress.street_address = this[role].streetName;
        localAddress.zip_code = this[role].zip;
        localAddress.city = this[role].city;
        localAddress.country = this[role].country;
        localAddress.contact_person = this[role].contactPerson;
        localAddress.email = this[role].email;
        localAddress.is_terminal = this[role].is_terminal;
        this[role].phone && (localAddress.phone_number = this[role].phoneFull.formattedNumber);
        if (personalization === PERSONALIZATION.COMPANY) {
          localAddress.company = this[role].company;
          (role === 'sender' || role === 'receiver') && this[role].vat_number && (localAddress.vat_number = this[role].vat_number);
        } else {
          localAddress.first_name = this[role].firstName;
          localAddress.last_name = this[role].lastName;
        }
        return localAddress;
      },
      queryTerminal(role) {
        let localAddress = {};
        localAddress.country = this[role].country;
        localAddress.is_terminal = this[role].is_terminal;
        localAddress.terminal_number = this[role].terminal_number;
        return localAddress;
      },
      queryData() {
        let queryData = new FormData;
        queryData.append('request_id', this.requestId);
        queryData.append('transport_mode', this.transportMode);
        queryData.append('offer_id', this.offerId);
        queryData.append('sender_address', JSON.stringify(this.queryAddress(this.requestData.send_from, 'sender')));
        if (this.sameCollectionAddress) {
          queryData.append('collection_address', JSON.stringify(this.queryAddress(this.requestData.send_from, 'sender')));
        }
        if (this.isTerminalCollection) {
          queryData.append('collection_address', JSON.stringify(this.queryTerminal('collection')));
        }
        if (this.isNotTerminalCollection) {
          queryData.append('collection_address', JSON.stringify(this.queryAddress(this.requestData.send_from, 'collection')));
        }
        queryData.append('receiver_address', JSON.stringify(this.queryAddress(this.requestData.send_to, 'receiver')));
        if (this.sameDeliveryAddress) {
          queryData.append('delivery_address', JSON.stringify(this.queryAddress(this.requestData.send_to, 'receiver')));
        }
        if (this.isTerminalDelivery) {
          queryData.append('delivery_address', JSON.stringify(this.queryTerminal('delivery')));
        }
        if (this.isNotTerminalDelivery) {
          queryData.append('delivery_address', JSON.stringify(this.queryAddress(this.requestData.send_to, 'delivery')));
        }
        this.loading_date && (queryData.append('loading_date', JSON.stringify(this.loading_date)));
        this.otherInformation && (queryData.append('other_info', this.otherInformation));
        if (this.uploadedDocumentsFile.length > 0) {
          this.uploadedDocumentsFile.forEach(file => {
            queryData.append('files[]', file.file);
          });
        }
        return queryData;
      },
      handleFileImport() {
        this.$refs.uploader.click();
      },
      onFileChanged(event) {
        for (let i = 0; i < event.target.files.length; i++) {
          this.uploadedDocumentsFile.push({
            id: Math.floor(Date.now() * Math.random()),
            name: event.target.files[i].name,
            file: event.target.files[i]
          });
        }
      },
      removeFile(id) {
        let deletingItemIndex = this.uploadedDocumentsFile.findIndex(x => x.id === id);
        this.uploadedDocumentsFile.splice(deletingItemIndex, 1);
      },
      async submitForm() {
        !this.$refs.formStep1.validate() && (this.bookingFormValid[0] = false);
        !this.$refs.formStep2.validate() && (this.bookingFormValid[1] = false);
        !this.$refs.formStep3.validate() && (this.bookingFormValid[2] = false);
        let errorStep = this.bookingFormValid.findIndex(value => value === false);
        if (errorStep === -1) {
          try {
            this.sendLoading = true;
            await api.bookOffer(this.queryData());
            methods.showGlobalSnackbar('success', localize('done'));
            this.$router.push({name: 'request-details', query: {request_id: this.requestId}, hash: '#quotes'});
          } catch (e) {
            this.sendLoading = false;
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        } else {
          this.activeStep = errorStep + 1;
        }
      },
    },
  };
</script>

<style scoped>
  .divider {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  .divider.active {
    border-color: #fc8101 !important;
    background-color: #fc8101 !important;
  }
</style>
